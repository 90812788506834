.fencing-page {

    .banner-secondary {
        background: $color-primary url(#{$path-img}fencing-banner2.jpg) no-repeat 100% 0;
        min-height: 630px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(lg) {
            background-position: 400px 0;
        }

        @include media-breakpoint-down(sm) {
            min-height: 472px;
            background-position: 0 0;
        }

        .banner-content {
            max-width: 680px;

            @include media-breakpoint-down(lg) {
                max-width: 470px;
            }
        }

        .title-block {
            padding: 0 0 27px;
            margin: 0 0 53px;

            @include media-breakpoint-down(md) {
                padding: 0 0 20px;
            }
        }
    }
}

.technology-section {
    background-image: radial-gradient($snow 0%, #f5f5f5 100%);
    padding: 123px 0 100px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        padding: 76px 0 70px;
    }

    @include media-breakpoint-down(sm) {
        padding: 40px 0 30px;
    }

    .title-block {

        @include media-breakpoint-down(md) {
            text-align: center;

            &:after {
                left: 50%;
                margin-left: -20px;
            }
        }
    }

    .content-holder,
    .technology-list{

        @include media-breakpoint-up(lg) {
            float: right;
            width: 47%;
        }
    }

    .content-holder {

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    .image-holder {
        text-align: center;

        @include media-breakpoint-up(lg) {
            float: left;
            width: 50%;
        }

        @include media-breakpoint-down(lg) {
            padding-top: 105px;
        }

        @include media-breakpoint-down(md) {
            padding-top: 46px;
        }

        @include media-breakpoint-down(sm) {
            padding-top: 17px;
        }
    }

    .technology-list {
        @extend %clear-list;
        padding-top: 10px;

        @include media-breakpoint-down(md) {
            padding-top: 54px;
            max-width: 496px;
            margin: 0 auto;
        }

        @include media-breakpoint-down(sm) {
            padding-top: 34px;
        }

        li {
            margin: 0 0 21px;

            @include media-breakpoint-down(lg) {
                margin: 0 0 12px;
            }

            &:first-child {

                .number {
                    background-color: #d8d8d8;
                    border-color: #a6a6a6;
                }
            }

            &:nth-child(2) {

                .number {
                    background-color: #a3d5d6;
                    border-color: #5f7c7d;
                }
            }

            &:nth-child(3) {

                .number {
                    background-color: #dbdda8;
                    border-color: #77785b;
                }
            }

            &:nth-child(4) {

                .number {
                    background-color: #e2d2ac;
                    border-color: #b37d00;
                }
            }

            p {
                overflow: hidden;
            }
        }

        .number {
            display: block;
            float: left;
            vertical-align: middle;
            border-radius: 50%;
            color: $color-text-secondary;
            width: 22px;
            height: 22px;
            text-align: center;
            border: 1px solid;
            margin-right: 20px;
            line-height: 1;
            padding-top: 4px;
        }
    }
}

.column-section {

    @include media-breakpoint-down(lg) {
        padding: 84px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 77px 0 56px;
    }

    @include media-breakpoint-down(sm) {
        text-align: left;
        padding: 40px 0 10px;
    }

    .title-block {
        text-align: center;
        max-width: none;

        &:after {
            left: 50%;
            margin-left: -20px;
        }
    }

    .text-block {

        @include media-breakpoint-down(md) {
            margin: 0;
        }
    }

    .content-column {

        @include media-breakpoint-up(lg) {
            columns: 2;
            column-gap: 65px;
        }
    }
}