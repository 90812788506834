.btn {
    font: 600 13px/1 $font-secondary;
    border-radius: 0;
    letter-spacing: 0.02em;
    color: $color-text-secondary;
    cursor: pointer;
}

.btn-primary {
    color: $color-primary;
    border-color: $color-secondary;
    background: transparent;
    padding: 12px 5px;
    min-width: 111px;

    &:hover {
        background: $color-secondary;
        border-color: $color-secondary;
    }

    &.btn-inverse {
        color: $color-inverse;
        font-weight: 500;
    }
}

.btn-secondary {
    color: $color-primary;
    background: $btn-secondary-color;
    border-color: $btn-secondary-color;

    &:hover {
        background: transparent;
        border-color: $btn-secondary-color;
    }
}

.btn-outline-secondary {
    border-color: $btn-secondary-color;
    color: $btn-secondary-color;

    &:hover {
        color: $color-primary;
        background: $btn-secondary-color;
        border-color: $btn-secondary-color;
    }
}

.btn-lg {
    font-size: 13px;
    padding: 17px 5px;
    min-width: 176px;
}

.btn-shadow {
    box-shadow: 0 4px 28px rgba($black, .5);
}