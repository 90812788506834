//Path

$path-fonts: '../fonts/';
$path-img: '../images/';

//Fonts
$roboto: 'Roboto';
$eurostile: 'Eurostile';

$font-primary: $roboto, sans-serif;
$font-secondary: $eurostile, sans-serif;
$font-heading: $font-secondary;

//Colors
$black: #000;
$gold: #b37d00;
$white: #fff;
$grey: #565656;
$snow: #fafafa;

$color-primary: $black;
$color-secondary: $gold;
$color-text: $grey;
$color-text-secondary: $black;
$color-inverse: $white;

//Default settings
$default-font-family: $font-primary;
$default-font-size: 14px;
$default-line-height: 1.71;
$default-font-weight: 400;
$default-font-style: normal;

//body
$body-bg: $white;

$btn-secondary-color: $white;
$footer-color: #7f7f7f;
$contact-section-color: #0a0a0a;
$main-menu-bg: #262522;
$banner-color: #a9a9a9;
$error-color: #ff0000;

$xl-max: 'screen and (max-width: 1366px)';