.title-block {
    position: relative;
    padding: 0 0 27px;
    margin: 0 auto 35px;
    max-width: 960px;
    font-family: $font-secondary;

    @include media-breakpoint-down(md) {
        padding: 0 0 20px;
        margin-bottom: 15px;
    }

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    .subtitle-holder {
        color: $color-secondary;
        font-weight: 500;
        font-size: 13px;
        text-transform: uppercase;
        margin: 0 0 23px;

        @include media-breakpoint-down(md) {
            margin: 0 0 15px;
        }

        .number {
            display: block;
            font-size: 19px;
            margin: 0 0 5px;
            font-weight: 600;
        }
    }

    .text-block & {
        margin: 0 0 45px;

        &:after {
            content: '';
            width: 40px;
            height: 2px;
            background: $color-secondary;
            position: absolute;
            left: 0;
            bottom: 0;

            @include media-breakpoint-down(sm) {
                left: 50%;
                margin-left: -20px;
            }
        }
    }
}

.text-section {
    padding: 125px 0 85px;

    @include media-breakpoint-down(md) {
        padding: 82px 0 5px;
    }

    @include media-breakpoint-down(sm) {
        padding: 52px 0 0;
        text-align: center;
    }

    .text-block {

        @include media-breakpoint-down(md) {
            margin: 0 0 74px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 0 45px;
        }
    }

    .title-block {
        margin: 0 0 50px;
        padding: 0 0 22px;
    }

    p {
        margin: 0 0 27px;
    }

    .custom-row {

        @include media-breakpoint-up(xl) {
            margin-left: -40px;
            margin-right: -40px;
        }
    }

    .custom-col {

        @include media-breakpoint-up(xl) {
            padding-right: 40px;
            padding-left: 40px;
        }
    }
}

.services-section {
    background-image: radial-gradient($white 0%, $snow 100%);
    padding: 105px 0 150px;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 105px 0 82px;
    }

    @include media-breakpoint-down(sm) {
        padding: 57px 0 65px;
    }

    &:after {
        content: '';
        width: 2px;
        height: 72px;
        background: $color-secondary;
        position: absolute;
        top: 0;
        left: 50%;

        @include media-breakpoint-down(sm) {
            height: 40px;
        }
    }

    .items-holder {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            text-align: center;
        }

        .item {

            &:not(:last-child) {

                @include media-breakpoint-down(sm) {
                    margin: 0 0 68px;
                }
            }
        }
    }

    .image-holder {
        margin: 0 0 33px;
    }

    .title {
        display: block;
        font: 600 20px/1 $font-heading;
        margin: 0 0 17px;
        color: $color-text-secondary;
    }

    .services-list {
        @extend %clear-list;
        margin: 0 0 42px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 35px;
        }

        li {
            margin: 0 0 8px;
        }
    }
}

.about-section {
    padding: 105px 0 147px;
    background: url(#{$path-img}about-bg.jpg) no-repeat 100% 0;

    @media #{$xl-max} {
        background-position: 300px 0;
    }

    @include media-breakpoint-down(lg) {
        background-position: 200px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 77px 0 84px;
    }

    @include media-breakpoint-down(sm) {
        background: url(#{$path-img}about-bg-mobile.jpg) no-repeat 100% 0;
        padding: 56px 0 63px;
    }

    .content-holder {
        max-width: 640px;

        @include media-breakpoint-down(lg) {
            max-width: 515px;
        }

        p {
            margin: 0 0 25px;
            line-height: 1.75;
        }
    }

    .logos-list {
        @extend %clear-list;
        padding-top: 35px;

        @include media-breakpoint-down(sm) {
            padding-top: 23px;
        }

        li {
            display: inline-block;
            vertical-align: middle;

            @include media-breakpoint-down(sm) {
                display: block;
                text-align: center;
            }

            &:not(:last-child) {
                margin-right: 102px;

                @include media-breakpoint-down(lg) {
                    margin-right: 85px;
                }

                @include media-breakpoint-down(sm) {
                    margin: 0 0 45px;
                }
            }
        }

        .btn {
            min-width: 144px;
        }
    }
}

.image-carousel-holder {
    padding: 0 40px;
    margin: 0 0 76px;

    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 15px;
        margin: 0 0 14px;
    }
}

.testimonials-section {
    background-image: radial-gradient($white 0%, $snow 100%);
    padding: 0 0 47px;

    @include media-breakpoint-down(sm) {
        padding: 0 0 41px;
    }

    .title-block {
        margin-bottom: 0;
        padding-bottom: 18px;
    }
}

.contact-us-section {
    padding: 163px 0 132px;
    background: $contact-section-color url(#{$path-img}contact-bg.jpg) no-repeat 0 0;
    overflow: hidden;

    @media #{$xl-max} {
        background-position: -300px 0;
    }

    @include media-breakpoint-down(lg) {
        background-position: -450px 0;
    }

    @include media-breakpoint-down(md) {
        background-position: 30% 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 56px 0;
    }

    .title-block {

        @include media-breakpoint-down(lg) {
            margin: 0;
        }

        @include media-breakpoint-down(md) {
            text-align: center;
            margin: 0 0 38px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0;
        }
    }

    .content-holder {
        float: right;
        max-width: 524px;

        @media #{$xl-max} {
            max-width: 390px;
        }

        @include media-breakpoint-down(md) {
            margin: 0 auto;
            float: none;
            max-width: 540px;
        }

        h2 {
            color: $color-inverse;
        }

        .phone {
            font: 600 29px/1 $font-secondary;
            display: inline-block;
            margin: 0 0 30px;
            color: $color-secondary;
            transition: color .15s;

            @include media-breakpoint-down(lg) {
                margin: 0 0 47px;
            }

            @include media-breakpoint-down(sm) {
                margin: 0 0 36px;
            }

            &:hover {
                text-decoration: none;
                color: $color-inverse;
            }
        }

        .btn {
            min-width: 145px;
            margin-top: 20px;
        }
    }

    .contact-form {

        @include media-breakpoint-down(sm) {
            max-width: 465px;
            margin: 0 auto;
        }
    }
}