html {
    height: 100%;
}

body {
    height: 100%;
    position: relative;
    font: #{$default-font-weight} #{$default-font-style} #{$default-font-size} / #{$default-line-height} #{$default-font-family};
    color: $color-text;
    background-color: $body-bg;
    min-width: 320px;
}

.layout {
    position: relative;
    overflow: hidden;
    min-height: 100%;
}

.container {
    max-width: 1470px;

    @media #{$xl-max} {
        max-width: 1170px;
    }

    @include media-breakpoint-down(lg) {
        max-width: 970px;
    }
}

img {
    max-width: 100%;
}

h1 {
    font: 500 48px/1.25 $font-heading;
    margin: 0 0 20px;
    color: $color-text-secondary;

    @include media-breakpoint-down(md) {
        font-size: 32px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 1.7;
    }
}

h2 {
    font: 600 28px/1.25 $font-heading;
    margin: 0 0 20px;
    letter-spacing: 0.03em;
    color: $color-text-secondary;

    @include media-breakpoint-down(sm) {
        font-size: 21px;
    }

    span {

        @include media-breakpoint-up(md) {
            color: $color-secondary;
        }
    }
}

.fancybox-skin {
    border-radius: 0;
}