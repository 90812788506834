.about-page {

    .about-section {
        background: $white;
        padding: 55px 0 109px;

        @include media-breakpoint-down(md) {
            padding: 55px 0 77px;
        }

        @include media-breakpoint-down(sm) {
            padding: 36px 0 78px;
        }

        .content-holder {
            margin: 0 auto;
            max-width: 940px;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .text-block {

            .title-block {

                &:after {
                    left: 50%;
                    margin-left: -20px;
                }
            }
        }

        .logos-list {

            @include media-breakpoint-down(md) {
                text-align: center;
                padding-top: 50px;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 20px;
            }
        }
    }
}