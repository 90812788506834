.header {
    background-image: linear-gradient(-45deg, #171717 0%, #141414 100%);
    font-family: $font-secondary;

    @include media-breakpoint-down(sm) {
        padding: 11px 0;
    }

    & > .container {

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .logo {
        width: 152px;

        @include media-breakpoint-down(md) {
            width: 120px;
        }

        @include media-breakpoint-down(sm) {
            width: 103px;
        }
    }

    .main-menu-holder {
        width: 100%;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: flex-start;
            flex-direction: row-reverse;
            align-items: center;
        }

        .phone {
            color: $color-secondary;
            font-size: 13px;
            font-weight: 600;
            margin-left: 35px;

            @include media-breakpoint-down(md) {
                margin-left: 27px;
            }

            @include media-breakpoint-down(sm) {
                margin-left: 0;
                position: absolute;
                top: 20px;
                right: 57px;
            }

            &:hover {
                text-decoration: none;
                color: $color-inverse;
            }
        }
    }

    .main-menu {

        @include media-breakpoint-down(sm) {
            position: absolute;
            top: 59px;
            left: 0;
            width: 100%;
            margin: 0;
            z-index: 99;
        }

        ul {
            @extend %clear-offset;

            @include media-breakpoint-down(sm) {
                padding-bottom: 47px;
                transition: transform .3s linear;
                transform: translateY(-100%);
                background-image: linear-gradient(-45deg, #171717 0%, #141414 100%);
            }

            .open-nav & {
                transform: translateY(0);
            }
        }

        li {
            float: left;
            list-style: none;

            @include media-breakpoint-down(sm) {
                float: none;
            }

            &.active {

                a {
                    color: $color-secondary;
                    background: $main-menu-bg;

                    @include media-breakpoint-down(sm) {
                        background: none;
                    }
                }
            }
        }
        
        a {
            display: block;
            color: $color-inverse;
            transition: color .15s, background .15s;
            font-size: 14px;
            font-weight: 500;
            padding: 24px 28px;

            @include media-breakpoint-down(md) {
                padding:  20px 11px;
            }

            @include media-breakpoint-down(sm) {
                padding: 10px 0;
                text-align: center;
            }

            &:hover {
                color: $color-secondary;
                text-decoration: none;
                background: $main-menu-bg;

                @include media-breakpoint-down(sm) {
                    background: none;
                }
            }
        }
    }

    .drop {

        @include media-breakpoint-down(sm) {
            overflow: hidden;
            max-height: 0;
            transition: max-height .3s linear;
            z-index: 999;
        }
    }

    .open-nav & {

        .drop {
            max-height: 1000px;
        }
    }
}

.j-toggle-nav {
    height: 10px;
    width: 14px;
    position:absolute;
    top: 25px;
    right: 19px;

    @include media-breakpoint-up(md) {
        display: none;
    }

    &:before,
    &:after,
    span {
        background: $color-secondary;
        position:absolute;
        top: 0;
        width: 14px;
        height: 2px;
    }

    &:before,
    &:after {
        content: '';
        top: 4px;
    }

    &:after {
        top: 8px;
    }

    .open-nav & {

        span {
            display: none;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
            top: 4px;
        }
    }
}