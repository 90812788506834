@import 'utils/variables';

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css';
@import '../../node_modules/font-awesome-sass/assets/stylesheets/font-awesome';
@import '../../node_modules/fancybox/dist/css/jquery.fancybox.css';


//utils
@import 'utils/mixins';
@import 'utils/fonts';

//components
@import 'components/default';
@import 'components/header';
@import 'components/footer';
@import 'components/buttons';
@import 'components/carousel';
@import 'components/forms';
@import 'components/banner';

//templates
@import 'templates/index';
@import 'templates/about';
@import 'templates/services';
@import 'templates/fencing';
@import 'templates/gates';
@import 'templates/contact';