.owl-carousel {

    .owl-dots {
        position: absolute;
        bottom: 59px;
        left: 0;
        width: 100%;
        text-align: center;

        @include media-breakpoint-down(md) {
            bottom: 46px;
        }

        @include media-breakpoint-down(sm) {
            bottom: 20px;
        }

        .owl-dot {
            display: inline-block;
            vertical-align: top;
            width: 40px;
            height: 1px;
            margin: 0 12px;
            background: $color-inverse;

            &.active {
                height: 2px;
                background: $color-secondary;
            }
        }
    }

    .owl-nav {

        .owl-prev,
        .owl-next {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            border: 1px solid $color-secondary;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 0;
            background: $color-inverse;
            transition: border .15s;

            &:hover {

                &:after {
                    border-color: $color-secondary;
                }
            }

            &:after {
                content: '';
                width: 13px;
                height: 13px;
                position: absolute;
                top: 50%;
                left: 17px;
                transform: translateY(-50%) rotate(45deg);
                border: 1px solid $color-primary;
                border-width: 1px 1px 0 0;
            }
        }

        .owl-prev {
            left: 0;

            &:after {
                left: 23px;
                transform: translateY(-50%) rotate(-135deg);
            }
        }

        .owl-next {
            right: 0;
        }
    }
}

.main-carousel {

    .item {
        background-size: cover;
        background-position: 100% 0;
        height: 733px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            height: 585px;
        }

        @include media-breakpoint-down(sm) {
            height: 508px;
        }
    }

    .slide-content {
        color: $color-inverse;
        width: 100%;
        text-align: center;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            padding: 0 50px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0 15px;
        }

        .content {
            max-width: 1050px;
            margin: 0 auto;
        }

        h1 {
            letter-spacing: .88px;
            color: $color-inverse;
        }

        .btn-holder {
            padding-top: 41px;

            @include media-breakpoint-down(sm) {
                padding-top: 20px;
            }

            .btn {
                margin: 0 15px 30px;
            }
        }
    }
}

.image-carousel {

    .item {
        box-shadow: 0 4px 14px rgba($color-primary, .2);
    }

    .owl-item {
        padding: 0 0 30px;
    }

    .owl-nav {

        .owl-prev,
        .owl-next {
            top: 45%;
        }

        .owl-prev {
            left: 10px;
        }

        .owl-next {
            right: 10px;
        }
    }
}

.testimonials-carousel {

    .owl-nav {

        @include media-breakpoint-down(md) {
            display: none;
        }

        .owl-prev,
        .owl-next {
            top: 26%;
            transform: none;
        }
    }

    .owl-stage-outer {
        margin: 0 0 70px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 40px;
        }
    }

    .slide-content {
        max-width: 700px;
        margin: 0 auto;
        text-align: center;

        .icon {
            display: inline-block;
            font: 500 112px/1 $font-secondary;
            color: $color-secondary;
            height: 50px;
            overflow: hidden;
            margin: 0 0 17px;
        }

        p {
            line-height: 1.4;
            margin: 0 0 20px;
        }

        .name {
            font: 600 17px/1 $font-secondary;
            color: $color-text-secondary;
        }
    }

    .owl-dots {
        position: static;

        .owl-dot {
            background: #4d4d4d;
        }
    }
}
