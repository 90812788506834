.form-control {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $color-inverse;
    height: 42px;
    padding: 0;
    font-size: 14px;
    background: none;

    @include placeholder {
        color: #b6b6b6;
    }

    &:focus {
        background: none;
        border-color: $color-secondary;
        box-shadow: none;
    }

    .contact-us-section & {
        color: $color-inverse;
    }
}

textarea {

    &.form-control {
        width: 100%;
        height: 140px;
        background: none;
        border: none;
        border-bottom: 1px solid $color-inverse;
        resize: none;
    }
}

.form-group {
    margin: 0 0 23px;
}

.contact-form {

    .form-control {

        &.error {
            border-color: $error-color;
        }
    }
}

.error {
    color: $error-color;
    font-size: 12px;
    margin: 0;
}

#contact-success {
    display: none;
    border: 1px solid $color-secondary;
    padding: 5px 10px;
    margin-top: 10px;
    text-align: center;
    color: $color-secondary;
    font-weight: 600;
    font-size: 13px;
}