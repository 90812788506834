.info-section {
    background-image: radial-gradient(#fafafa 0%, #f5f5f5 100%);
    padding: 115px 0 90px;

    @include media-breakpoint-up(lg) {
        text-align: center;
    }

    @include media-breakpoint-down(md) {
        padding: 75px 0 60px;
    }

    @include media-breakpoint-down(sm) {
        padding: 44px 0 22px;
        text-align: center;
    }

    .title-block {
        max-width: none;

        &:after {

            @include media-breakpoint-up(lg) {
                left: 50%;
                margin-left: -20px;
            }
        }
    }

    .content-holder {
        max-width: 1080px;
        margin: 0 auto;

        p {
            margin: 0 0 25px;
        }

    }
}