.footer {
    background-image: linear-gradient(-45deg, #171717 0%, #141414 100%);
    padding: 36px 0 20px;
    color: $footer-color;

    @include media-breakpoint-down(sm) {
        padding: 44px 0 31px;
    }

    .footer-row,
    .footer-top {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            text-align: center;
        }
    }

    .footer-top {
        margin: 0 0 65px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 43px;
        }
    }

    .footer-logo {
        width: 81px;

        @include media-breakpoint-down(sm) {
            margin: 0 auto 43px;
        }
    }
    
    .footer-nav {
        @extend %clear-list;
        
        li {
            display: inline-block;
            vertical-align: top;

            @include media-breakpoint-down(sm) {
                display: block;
            }
            
            &:not(:last-child) {
                margin-right: 62px;

                @include media-breakpoint-down(md) {
                    margin-right: 50px;
                }

                @include media-breakpoint-down(sm) {
                    margin: 0 0 25px;
                }
            }
        }
        
        a {
            color: $color-inverse;

            &:hover {
                text-decoration: none;
                color: $color-secondary;
            }
        }
    }

    .copy-holder {
        font-size: 12px;

        @include media-breakpoint-down(sm) {
            margin: 0 0 38px;
        }

        p {
            vertical-align: top;
            margin: 0;

            @include media-breakpoint-up(md) {
                display: inline-block;
            }
        }

        .social {
            position: relative;
            display: inline-block;
            color: $footer-color;

            @include media-breakpoint-up(md) {
                padding-left: 15px;
                margin-left: 10px;
            }

            &:hover {
                text-decoration: none;
                color: $color-secondary;
            }

            &:before {
                content: '';
                width: 1px;
                height: 10px;
                background: $footer-color;
                position: absolute;
                top: 5px;
                left: 0;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }

    .created {
        font-size: 12px;

        span {
            color: $color-inverse;
            margin-right: 10px;

            @include media-breakpoint-down(sm) {
                display: block;
                margin: 0 0 2px;
            }
        }
    }
}