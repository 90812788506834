.banner {
    color: $banner-color;

    .about-page & {
        height: 185px;
        background: $color-primary url(#{$path-img}about-banner.jpg) no-repeat 100% 0 / cover;

        @include media-breakpoint-down(lg) {
            height: 160px;
        }

        @include media-breakpoint-down(md) {
            height: 105px;
            background-position: 100% 0;
        }

        @include media-breakpoint-down(sm) {
            height: 100px;
            background-position: 100% 0;
        }
    }

    .services-page & {
        background: $color-primary url(#{$path-img}services-banner.jpg) no-repeat 100% 0 / auto 100%;

        @include media-breakpoint-down(md) {
            background-position: 100px 0;
        }

        @include media-breakpoint-down(sm) {
            background-position: -275px 0;
        }
    }
    
    .gates-page & {
        background: $color-primary url(#{$path-img}gates-banner.jpg) no-repeat 100% 0 / auto 100%;

        @include media-breakpoint-down(lg) {
            background-position: 135px 0;
        }

        @include media-breakpoint-down(md) {
            background-position: 0 0;
        }

        @include media-breakpoint-down(sm) {
            background-position: -100px 0;
        }
    }

    .fencing-page & {
        background: $color-primary url(#{$path-img}fencing-banner.jpg) no-repeat 100% 0 / auto 100%;

        @include media-breakpoint-down(lg) {
            background-position: 300px 0;
        }

        @include media-breakpoint-down(sm) {
            background-position: -300px 0;
        }
    }

    .contact-page & {
        background: $color-primary url(#{$path-img}contact-banner.jpg) no-repeat 100% 0 / auto 100%;
        min-height: 664px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(lg) {
            background-position: 135px 0;
        }

        @include media-breakpoint-down(sm) {
            background-position: 90px 0;
            min-height: 490px;
        }

        .banner-content {
            max-width: 530px;

            @include media-breakpoint-down(md) {
                max-width: 350px;
            }

            @include media-breakpoint-down(sm) {
                text-align: left;
                max-width: 225px;
            }

            .title-block {
                padding: 0 0 20px;
                margin: 0 0 50px;

                @include media-breakpoint-down(sm) {
                    text-align: left;
                    margin: 0 0 40px;
                }

                &:after {

                    @include media-breakpoint-down(sm) {
                        left: 0;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .banner-content {
        padding: 75px 0 105px;

        @include media-breakpoint-up(md) {
            max-width: 550px;
        }

        @include media-breakpoint-down(sm) {
            padding: 45px 0 73px;
            text-align: center;
        }

        .title-block {
            padding: 0;
            margin: 0 0 41px;

            h2 {
                color: $color-inverse;
            }
        }

        .banner-list {
            @extend %clear-list;
            margin: 0 0 54px;

            li {
                margin: 0 0 8px;
            }
        }

        .btn {
            min-width: 143px;
        }

        .phone {
            color: $color-secondary;
            font: 600 38px/1 $font-secondary;
            transition: color .15s;

            @include media-breakpoint-down(sm) {
                font-size: 29px;
            }

            &:hover {
                text-decoration: none;
                color: $color-inverse;
            }
        }
    }
}